<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="责任类型" prop="kind">
        <a-radio-group v-model="form.kind" button-style="solid">
          <a-radio-button v-for="(d, index) in kindOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <!-- <a-form-model-item label="自编号" prop="kindNo" >
        <a-input v-model="form.kindNo" placeholder="请输入责任对象编号" />
      </a-form-model-item>-->
      <a-form-model-item label="车辆自编号" prop="kindNo">
        <a-select
          show-search
          placeholder="请输入责任对象编号"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleKindNoSearch"
          @change="handleKindNoChange"
          :loading="loading"
          v-model="form.kindNo"
        >
          <a-select-option v-for="(d, index) in vehicleNoArray" :key="index">{{ d.vehicleNo }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="车牌号" prop="licensePlateNum">
        <a-select
          show-search
          allowClear
          placeholder="请输入车辆车牌号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleLicensePlateNumSearch"
          @change="handleLicensePlateNumChange"
          :loading="loading"
          v-model="form.licensePlateNum"
        >
          <a-select-option
            v-for="d in vehicleNoArray"
            :key="d.licensePlateNum"
          >{{ d.licensePlateNum }}</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="责任对象ID" prop="kindId" >
        <a-input v-model="form.kindId" placeholder="请输入责任对象ID" />
      </a-form-model-item>-->
      <a-form-model-item label="责任人" prop="employeeName">
        <a-select
          show-search
          placeholder="请输入责任人姓名查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handlePrincipalsSearch"
          @change="handlePrincipalsChange"
          :loading="loading"
          v-model="form.employeeName"
        >
          <a-select-option v-for="d in personnelArray" :key="d.id">{{ d.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="责任人姓名" prop="employeeName" >
        <a-input v-model="form.employeeName" placeholder="请输入责任人姓名" />
      </a-form-model-item>-->
      <a-form-model-item label="责任人开始负责日期" prop="startDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.startDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="责任人结束负责日期" prop="endDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.endDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">保存</a-button>
          <a-button type="dashed" @click="cancel">取消</a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPrincipals, addPrincipals, updatePrincipals } from '@/api/iot/principals'
import { listEmployeeAccount } from '@/api/iot/employeeAccount'
import { searchCarNo, listVehicleAccount } from '@/api/iot/vehicleAccount'
import debounce from 'lodash/debounce'

export default {
  name: 'CreateForm',
  props: {
    kindOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    // 节流控制
    this.handlePrincipalsSearch = debounce(this.handlePrincipalsSearch, 500)
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        kind: null,
        kindNo: null,
        kindId: null,
        employeeId: null,
        employeeName: null,
        startDate: null,
        endDate: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        kind: [{ required: true, message: '请选择责任类型', trigger: 'blur' }],
        kindNo: [{ required: true, message: '责任对象编号不能为空', trigger: 'blur' }],
        licensePlateNum: [{ required: true, message: '车牌号不能为空', trigger: 'blur' }],
        kindId: [{ required: true, message: '责任对象ID不能为空', trigger: 'blur' }],
        employeeId: [{ required: true, message: '责任人ID不能为空', trigger: 'blur' }],
        employeeName: [{ required: true, message: '责任人姓名不能为空', trigger: 'blur' }],
        startDate: [{ required: true, message: '责任人开始负责日期不能为空' }],
        endDate: [{ required: true, message: '责任人结束负责日期不能为空' }]
      },
      vehicleNoArray: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        kind: null,
        kindNo: null,
        kindId: null,
        employeeId: null,
        employeeName: null,
        startDate: null,
        endDate: null,
        licensePlateNum: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPrincipals(id).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePrincipals(this.form).then((response) => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addPrincipals(this.form).then((response) => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handlePrincipalsSearch(value) {
      console.log('handlePersonnelSearch', value)
      const queryParam = {
        name: value
      }
      this.loading = true
      listEmployeeAccount(queryParam).then((response) => {
        this.personnelArray = response.rows
        this.loading = false
      })
    },
    handlePrincipalsChange(value, option) {
      console.log('options=======:{}', option)
      console.log('value=======:{}', value)
      this.form.employeeId = value
      const tmpArray = this.personnelArray.filter((p) => p.id === value)
      if (tmpArray.length > 0) {
        this.form.employeeName = tmpArray[0].name
        this.$forceUpdate()
      }
    },
    getListVehicleAccount() {
      console.log('getListPrincipals')
      listVehicleAccount({ vehicleNo: this.form.kindNo }).then((response) => {
        this.form.kindId = response.rows[0].id
      })
    },
    handleKindNoSearch(value) {
      console.log('handleKindNoSearch', value)
      const queryParam = {
        vehicleNo: value
      }
      this.loading = true
      // 搜索车辆自编号
      searchCarNo(queryParam).then((response) => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleKindNoChange(value) {
      // 选择后设置自编号
      this.form.kindNo = this.vehicleNoArray[value].vehicleNo
      this.getListVehicleAccount()
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      const queryParam = {
        licensePlateNum: value
      }
      this.loading = true
      searchCarNo(queryParam).then((response) => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleLicensePlateNumChange(value) {
      this.form.licensePlateNum = value
      // 设置车辆自编号
      const tmpArray = this.vehicleNoArray.filter((p) => p.licensePlateNum === value)
      if (tmpArray.length > 0) {
        this.form.kindNo = tmpArray[0].vehicleNo
        this.$forceUpdate()
      }
    }
  }
}
</script>
