import request from '@/utils/request'

// 查询责任人列表
export function listPrincipals (query) {
  return request({
    url: '/iot/principals/list',
    method: 'get',
    params: query
  })
}

// 查询责任人详细
export function getPrincipals (id) {
  return request({
    url: '/iot/principals/' + id,
    method: 'get'
  })
}

// 新增责任人
export function addPrincipals (data) {
  return request({
    url: '/iot/principals',
    method: 'post',
    data: data
  })
}

// 修改责任人
export function updatePrincipals (data) {
  return request({
    url: '/iot/principals',
    method: 'put',
    data: data
  })
}

// 删除责任人
export function delPrincipals (id) {
  return request({
    url: '/iot/principals/' + id,
    method: 'delete'
  })
}

// 导出责任人
export function exportPrincipals (query) {
  return request({
    url: '/iot/principals/export',
    method: 'get',
    params: query
  })
}
